import React, { useState } from 'react'
import cx from 'classnames'
import styles from './faq.module.css'

import { Section } from 'components/Section'
import { Header } from 'components/Header'
import { Button } from 'components/Button'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

const cards = [
  {
    key: 'deposit',
    title: 'Что принимается в качестве залога при оформлении займа?',
    body: (
      <p>
        В качестве залога принимаются изделия из золота и их части (лом) с
        пробой от 333 до 999.
      </p>
    ),
  },
  {
    key: 'anotherPerson',
    title:
      'Может ли другой человек погасить мой заём и забрать залоговое изделие?',
    body: (
      <p>
        Полное или частичное погашение может сделать любой человек старше 18
        лет, а вот залоговое обеспечение может получить заёмщик или его
        доверенное лицо, на которого оставлена доверенность.
      </p>
    ),
  },
  {
    key: 'percent',
    title: 'Как оплачиваются проценты по займу?',
    body: (
      <p>
        Вы можете оплатить проценты в любом нашем филиале или через терминалы
        оплаты. Мы не требуем предоплаты процентов. Платите только по факту.
      </p>
    ),
  },
]

const moreCards = [
  {
    key: 'gold',
    title: 'От чего зависит оценка золотых изделий?',
    body: (
      <p>
        Оценка золотых изделий зависит от содержания чистого золота в сплаве,
        текущей биржевой стоимости золота и наличия в украшениях бриллиантов.
      </p>
    ),
  },
  {
    key: 'lombard',
    title: 'Кто может воспользоваться услугами ломбарда?',
    body: (
      <p>
        Приём изделий из драгоценных металлов в залог производится у лиц,
        достигших 18 лет, при предъявлении одного из вышеперечисленных
        документов. Наличие регистрации (прописки) или гражданства РТ не
        является необходимым.
      </p>
    ),
  },
  {
    key: 'plshelp',
    title: 'Что делать, если я потерял залоговый билет?',
    body: (
      <p>
        Ничего страшного! В случае утраты залогового билета вам нужно просто
        обратиться в ломбард, в котором был оформлен заем, и предъявить
        документ, удостоверяющий личность!
      </p>
    ),
  },
  {
    key: 'docs',
    title: 'Какие документы нужны, чтобы сдать золото в ломбард?',
    body: (
      <p>
        Для получения займа под залог изделия требуется один из
        нижеперечисленных документов, удостоверяющих личность:
        <ul>
          <li>паспорт гражданина РТ;</li>
          <li>заграничный паспорт РТ;</li>
          <li>военный билет;</li>
          <li>паспорт иностранного гражданина;</li>
          <li>водительское удостоверение.</li>
        </ul>
      </p>
    ),
  },
  {
    key: 'term',
    title: 'На какой срок выдается займ?',
    body: (
      <>
        <p>
          Займ выдается на срок от 1 до 60 дней (+ 30 дней льготный период).
        </p>
        <p>
          Минимальный срок пользования займом не ограничен. В случае погашения
          займа ранее окончания его срока, процент за пользование займом будет
          начислен исходя из фактического количества дней пользования займом.
        </p>
        <p>
          До наступления срока реализации ценностей залогодатель вправе
          востребовать их, рассчитавшись с ломбардом по обязательствам возврата
          ссуды и процентов по ней.
        </p>
      </>
    ),
  },
  {
    key: 'hell',
    title: 'Что будет, если я не выкуплю изделие?',
    style: { flexBasis: '1000px' },
    body: (
      <>
        <p>
          По истечении срока кредитования, заложенное имущество (изделия из
          драгоценных металлов) находится на ответственном хранении ломбарда в
          течение 30 дней (льготный период).
        </p>
        <p>
          В случае неисполнения залогодателем своих обязательств по возврату
          суммы займа и процентов по ней в срок, указанный в залоговом билете, с
          учетом льготного месяца, ломбард вправе реализовать заложенное
          имущество и получить удовлетворение своих требований из полученной
          суммы в соответствии с действующим законодательством.
        </p>
        <p>
          После реализации ваших изделий у вас отсутствуют какие-либо
          обязательства перед ломбардом! Мы не подаем информацию в бюро
          кредитных историй, не занимаемся взысканием долгов через коллекторские
          агентства и не будем вас тревожить надоедливыми звонками с требованием
          вернуть займ! Невозврат займа никак не влияет на ваше обслуживание в
          наших ломбардах в будущем!
        </p>
      </>
    ),
  },
]

const FAQ = () => {
  const [isShowMore, setIsShowMore] = useState(false)
  return (
    <Section id="faq">
      <Header as="h2">Популярные вопросы</Header>
      <TransitionGroup>
        <div className={styles.cardsContainer}>
          {(isShowMore ? [...cards, ...moreCards] : cards).map(card => (
            <CSSTransition
              in
              appear
              key={card.key}
              timeout={{ enter: 120 }}
              unmountOnExit
              classNames={styles}
              style={card.style}
            >
              <div key={card.key} className={cx(styles.card, styles.showMore)}>
                <Header as="h3">{card.title}</Header>
                {card.body}
              </div>
            </CSSTransition>
          ))}
        </div>
      </TransitionGroup>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.button}
          onClick={() => setIsShowMore(old => !old)}
        >
          {isShowMore ? 'Спрятать' : 'Просмотреть все'}
        </Button>
      </div>
    </Section>
  )
}

export { FAQ }
